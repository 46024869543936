import React from "react";
import SwiperCore from "swiper";

import { GatsbyImage } from "gatsby-plugin-image";
import { Navigation } from "swiper";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Ref } from "react";
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import ArrowLeftIcon from "@Js/Component/Icon/ArrowLeftIcon";
import ArrowRightIcon from "@Js/Component/Icon/ArrowRightIcon";
import EyeIcon from "@Js/Component/Icon/EyeIcon";
import Heading from "@Js/Component/Heading";
import LinkButton from "@Js/Component/Button/LinkButton";
import Service from "@Js/Type/Service";
import SmallArrowRightIcon from "@Js/Component/Icon/SmallArrowRightIcon";

import useMediaQuery from "@Js/Hook/useMediaQuery";

import "swiper/css";

import * as classNames from "@Css/Component/Block/OurWorkBlock.module.scss";

SwiperCore.use([Navigation]);

/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        uri: string;
    };
};

/**
 * @type OurWorkBlockProps
 */
export type OurWorkBlockProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children"> & {
    projectEdges: {
        node: {
            details: {
                services: Service[];
            };
            featuredImage?: {
                node: {
                    localFile: {
                        childImageSharp: {
                            gatsbyImageData: IGatsbyImageData;
                        };
                    };
                };
            };
            id: string;
            title: string;
            uri: string;
        };
    }[];
};

/**
 * @const OurWorkBlock
 */
const OurWorkBlock = (props: OurWorkBlockProps, ref: Ref<HTMLElement>) => {
    const {
        className,
        projectEdges,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const isDesktop = useMediaQuery("(min-width: 1024px)");

    const next = useRef<HTMLButtonElement | null>();
    const prev = useRef<HTMLButtonElement | null>();

    const data: WordpressPageData = useStaticQuery(graphql`
        query {
            wordpressPage(isProjectsPage: { eq: true }) {
                uri
            }
        }
    `);

    const getService = (service: Service) => {
        switch (service) {
            case "e_commerce":
                return t("E-commerce");

            case "web_application":
                return t("Web Application");

            case "website":
                return t("Website");
        }
    };

    const onSwiperInit = (swiper: SwiperCore) => {
        if (swiper.params.navigation) {

            // @ts-ignore
            swiper.params.navigation.prevEl = prev.current;

            // @ts-ignore
            swiper.params.navigation.nextEl = next.current;
        }

        swiper.navigation.init();
        swiper.navigation.update();
    };

    return (
        <section { ...restProps } className={ clsx(classNames.ourWorkBlock, className) } ref={ ref }>
            <div className={ classNames.container }>
                <header className={ classNames.header }>
                    <Heading className={ classNames.heading }>{ t("Our Work") }</Heading>
                    <button
                        aria-label={ t("Previous") }
                        className={ classNames.prevArrowButton }
                        ref={ (ref) => prev.current = ref }
                    >
                        <ArrowLeftIcon />
                    </button>
                    <button
                        aria-label={ t("Next") }
                        className={ classNames.nextArrowButton }
                        ref={ (ref) => next.current = ref }
                    >
                        <ArrowRightIcon />
                    </button>
                </header>
                <Swiper
                    onInit={ onSwiperInit }
                    slidesPerView={ isDesktop ? 2 : 1 }
                    spaceBetween={ isDesktop ? 50 : 20 }
                >
                    { projectEdges.map((edge) => (
                        <SwiperSlide key={ edge.node.id }>
                            <Link className={ classNames.link } to={ edge.node.uri }>
                                { edge.node.featuredImage && (
                                    <GatsbyImage
                                        alt={ edge.node.title }
                                        className={ classNames.image }
                                        image={ edge.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData }
                                    />
                                ) }
                                <div className={ classNames.overlay } />
                                <section className={ classNames.container }>
                                    <Heading className={ classNames.services } element="h2">
                                        { edge.node.details.services.map((service, index) => (
                                            <span className={ classNames.service } key={ service }>
                                                { getService(service) }
                                                { !(index == edge.node.details.services.length - 1) ? ", " : "" }
                                            </span>
                                        )) }
                                    </Heading>
                                    <Heading className={ classNames.heading }>{ edge.node.title }</Heading>
                                </section>
                                <div className={ classNames.iconWrapper }>
                                    <EyeIcon className={ classNames.icon } />
                                </div>
                            </Link>
                        </SwiperSlide>
                    )) }
                </Swiper>
                <div className={ classNames.buttonWrapper }>
                    <LinkButton className={ classNames.button } to={ data.wordpressPage.uri } variant="outlined">
                        <span>{ t("All projects") }</span>
                        <SmallArrowRightIcon className={ classNames.icon } />
                    </LinkButton>
                </div>
            </div>
        </section>
    );
};

export default forwardRef(OurWorkBlock);
