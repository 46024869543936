// extracted by mini-css-extract-plugin
export var ourWorkBlock = "OurWorkBlock-module--ourWorkBlock--3fNL_";
export var container = "OurWorkBlock-module--container--22cAr";
export var header = "OurWorkBlock-module--header--1CZGZ";
export var heading = "OurWorkBlock-module--heading--nUWtA";
export var nextArrowButton = "OurWorkBlock-module--nextArrowButton--1skBD";
export var prevArrowButton = "OurWorkBlock-module--prevArrowButton--2rNZ0";
export var link = "OurWorkBlock-module--link--2BHoO";
export var image = "OurWorkBlock-module--image--2WfOz";
export var iconWrapper = "OurWorkBlock-module--iconWrapper--1iBId";
export var overlay = "OurWorkBlock-module--overlay--nhBQK";
export var services = "OurWorkBlock-module--services--1vEA6";
export var service = "OurWorkBlock-module--service--1HpQG";
export var icon = "OurWorkBlock-module--icon--2IFv3";
export var buttonWrapper = "OurWorkBlock-module--buttonWrapper--1IUiK";
export var button = "OurWorkBlock-module--button--3dfUP";