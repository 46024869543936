import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { IGatsbyImageData } from "gatsby-plugin-image";
import { PageProps } from "gatsby";
import { Ref } from "react";

import { forwardRef } from "react";
import { useContext } from "react";
import { useLayoutEffect } from "react";

import ThemeContext from "@Js/Context/ThemeContext";

import Seo from "@Js/Component/Seo";

import Gutenberg from "@Js/Component/Gutenberg";
import OurWorkBlock from "@Js/Component/Block/OurWorkBlock";
import ServiceType from "@Js/Type/Service";
import WhatWeCanDoForYouBlock from "@Js/Component/Block/WhatWeCanDoForYouBlock";

import * as classNames from "@Css/Component/Page/Service.module.scss";

/**
 * @type ServiceProps
 */
export type ServiceProps = PageProps & {
    pageContext: {
        pageEdge: {
            node: {
                content?: string;
                extraContent?: string;
                seo: {
                    canonical: string;
                    metaDesc: string;
                    metaKeywords: string;
                    metaRobotsNofollow: string;
                    metaRobotsNoindex: string;
                    opengraphAuthor: string;
                    opengraphDescription: string;
                    opengraphModifiedTime: string;
                    opengraphPublishedTime: string;
                    opengraphPublisher: string;
                    opengraphSiteName: string;
                    opengraphTitle: string;
                    opengraphType: string;
                    opengraphUrl: string;
                    schema: {
                        raw: string;
                    };
                    title: string;
                };
                topImages: {
                    topImageOne: {
                        altText: string;
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData;
                            };
                        };
                    };
                    topImageTwo: {
                        altText: string;
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData;
                            };
                        };
                    };
                };
            };
        };
        projectEdges: {
            node: {
                details: {
                    services: ServiceType[];
                };
                featuredImage?: {
                    node: {
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData;
                            };
                        };
                    };
                };
                id: string;
                title: string;
                uri: string;
            };
        }[];
    };
};

/**
 * @const Service
 */
const Service = (props: ServiceProps, ref: Ref<HTMLElement>) => {
    const {
        pageContext
    } = props;

    const {
        setHeaderBackground,
        setIsLogoColorless,
        setIsMenuColorless
    } = useContext(ThemeContext);

    useLayoutEffect(() => {
        setHeaderBackground("#FFFFFF");

        setIsLogoColorless(false);
        setIsMenuColorless(false);
    }, []);

    /**
     * @TODO: When clicking on "Show Projects", only show projects that belong to this service by setting the default of the filter
     */

    return (
        <>
            <Seo { ...pageContext.pageEdge.node.seo } />
            <section className={ classNames.service } ref={ ref }>
                { pageContext.pageEdge.node.content && (
                    <Gutenberg className={ classNames.gutenberg } content={ pageContext.pageEdge.node.content } />
                ) }
                <div className={ classNames.service }>
                    <div className={ classNames.topImages }>
                        <GatsbyImage
                            alt={ pageContext.pageEdge.node.topImages.topImageOne.altText }
                            className={ classNames.topImage }
                            image={
                                pageContext.pageEdge.node.topImages.topImageOne.localFile.childImageSharp.gatsbyImageData
                            }
                        />
                        <GatsbyImage
                            alt={ pageContext.pageEdge.node.topImages.topImageTwo.altText }
                            className={ classNames.topImage }
                            image={
                                pageContext.pageEdge.node.topImages.topImageTwo.localFile.childImageSharp.gatsbyImageData
                            }
                        />
                    </div>
                    { pageContext.pageEdge.node.extraContent && (
                        <Gutenberg className={ classNames.extraContent } content={ pageContext.pageEdge.node.extraContent } />
                    ) }
                </div>
                <div className={ classNames.ourWorkBlockWrapper }>
                    <OurWorkBlock projectEdges={ pageContext.projectEdges } />
                </div>
                <WhatWeCanDoForYouBlock />
            </section>
        </>
    );
};

export default forwardRef(Service);
